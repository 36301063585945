<template>
  <div v-loading="loading" class="table_box">
    <div class="table_content">
      <div class="table_title">
        <span v-for="(item, index) in tableTitleArr" ::key="index">{{
          item
        }}</span>
      </div>
      <div v-if="tableData.length > 0" class="table_conter">
        <div v-for="(item, index) in tableData" :key="index" class="conter_box">
          <!-- 序号 -->
          <span class="span_item">{{ item.id }}</span>
          <!-- 姓名 -->
          <span class="span_item">{{ item.applicant }}</span>
          <!-- 身份证 -->
          <span class="span_item">{{ item.cardId }}</span>
          <!-- 年龄 -->
          <span class="span_item">{{ item.age }}</span>
          <!-- 电话 -->
          <span class="span_item">{{ item.phone }}</span>
          <!-- 住址 -->
          <span class="span_item">{{ item.addr }}</span>
          <!-- 是否贫困户 -->
          <span class="span_item">{{
            item.poorFlag == "1" ? "是" : "否"
          }}</span>
          <!-- 发放情况 -->
          <span class="span_item">{{ item.wishSituation }}</span>
          <span class="span_item">
            <span @click="deleteBtn(item)" class="delete_btn">删除</span>
            <span @click="editBtn(item)" class="edit_btn">编辑</span>
          </span>
        </div>
      </div>
      <div class="table_conter conter_no_data" v-else>暂无数据</div>
    </div>
    <div v-if="total > 0" class="pagination_box">
      <el-pagination
        :page-size="formInline.pageSize"
        :current-page="formInline.pageNum"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @current-change="changeNum"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    tableData: Array,
    formInline: Object,
    total: Number,
    loading: Boolean,
  },
  components: {},
  data() {
    return {
      tableTitleArr: [
        "序号",
        "姓名",
        "身份证",
        "年龄",
        "电话",
        "住址",
        "是否贫困户",
        "发放情况",
        "操作",
      ],
    };
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    changeNum(val) {
      this.$emit("changePage", val);
    },
    editBtn(row) {
      this.$emit("editDialog", row);
    },
    deleteBtn(row) {
      this.$emit("onDelete", row);
    },
  },
};
</script>
<style lang='scss' scoped>
.table_box {
  font-size: 0.8vw;
  margin-top: 0.5vw;
  .table_content {
    padding: 1vw;
    span {
      display: inline-block;
      width: 10vw;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &:last-child {
        span {
          width: 3vw;
          cursor: pointer;
        }
        .delete_btn {
          border-right: 0.1vw solid #fff;
          color: #73ff87;
        }
        .edit_btn {
          color: #379aff;
        }
      }
    }
    .table_title {
      padding: 0.4vw 0;
      color: #5384e5;
      background: rgba(6, 83, 110, 0.2);
    }
    .table_conter {
      color: #bfdaff;
      // border: 0.1vw solid #5384e5;
      .conter_box {
        padding: 0.8vw 0;
        &:nth-child(4n + 1) {
          background: rgba(6, 83, 110, 0.4);
        }
        &:nth-child(4n + 2) {
          background: rgba(6, 83, 110, 0.6);
        }
        &:nth-child(4n + 3) {
          background: rgba(6, 83, 110, 0.4);
        }
        &:nth-child(4n + 4) {
          background: rgba(6, 83, 110, 0.2);
        }
      }
    }
    .conter_no_data {
      text-align: center;
      padding: 1vw;
    }
  }
  .pagination_box {
    text-align: right;
    margin: 0.5vw 1vw 1vw;
    ::v-deep {
      .el-pagination.is-background .el-pager li:not(.disabled) {
        background: rgba(23, 95, 173, 0.4);
        color: #2e55a3;
      }
      .el-pagination.is-background .el-pager li:not(.disabled).active {
        background: url("../../../assets/image/btn-xz.png") no-repeat;
        background-size: 100% 100%;
        color: #fff;
      }
      .el-icon-arrow-left:before {
        content: "上一页";
      }
      .el-icon-arrow-right:before {
        content: "下一页";
      }
      .btn-next,
      .btn-prev {
        padding: 0 1vw;
        background: rgba(22, 95, 173, 0.4);
        color: #7faaff;
      }
      .el-pagination__total,
      .el-pagination__jump {
        color: #fff;
      }
    }
  }
}
</style>