<template>
  <div v-if="dialog" class="dialog_cover_cloth">
    <div class="dialog_box">
      <div class="box_content">
        <div class="bg_img">
          <img src="@/assets/image/idx-tk.png" alt="" />
        </div>
        <div class="content_box">
          <div class="box_header">
            <div class="title">爱心包裹信息填写</div>
            <div @click="closeDialog" class="close_btn">X</div>
          </div>
          <div class="box_conter">
            <div class="conter_left">
              <div class="form_row">
                <div class="label_span">姓名:</div>
                <div class="value_span">
                  <el-input
                    onKeypress="javascript:if(event.keyCode == 32)event.returnValue = false;"
                    v-model="rowData.applicant"
                  ></el-input>
                </div>
              </div>
              <div class="form_row">
                <div class="label_span">年龄:</div>
                <div class="value_span">
                  <el-input
                    onKeypress="javascript:if(event.keyCode == 32)event.returnValue = false;"
                    v-model="rowData.age"
                  ></el-input>
                </div>
              </div>
              <div class="form_row">
                <div class="label_span">是否贫困户:</div>
                <div class="value_span">
                  <el-select
                    style="width: 100%"
                    v-model="rowData.poorFlag"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="(item, index) in isPoorHouseholdsArr"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div class="conter_right">
              <div class="form_row">
                <div class="label_span">身份证:</div>
                <div class="value_span">
                  <el-input
                    onKeypress="javascript:if(event.keyCode == 32)event.returnValue = false;"
                    v-model="rowData.cardId"
                  ></el-input>
                </div>
              </div>
              <div class="form_row">
                <div class="label_span">电话:</div>
                <div class="value_span">
                  <el-input
                    onKeypress="javascript:if(event.keyCode == 32)event.returnValue = false;"
                    v-model="rowData.phone"
                  ></el-input>
                </div>
              </div>
              <div class="form_row">
                <div class="label_span">发放情况:</div>
                <div class="value_span">
                  <el-select
                    style="width: 100%"
                    v-model="rowData.wishSituation"
                    placeholder="请选择"
                    @change="changeWishSituation"
                  >
                    <el-option
                      v-for="(item, index) in distributionArr"
                      :key="index"
                      :label="item.distribution"
                      :value="item.distribution"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div class="conter_bottom">
              <div class="form_row">
                <div class="label_span">住址:</div>
                <div class="value_span">
                  <el-input
                    onKeypress="javascript:if(event.keyCode == 32)event.returnValue = false;"
                    v-model="rowData.addr"
                  ></el-input>
                </div>
              </div>
            </div>
          </div>
          <div class="box_footer">
            <div class="btn_box">
              <div @click="closeDialog" class="close_btn">关闭</div>
              <div
                :loading="submitBtnLoading"
                @click="submitBtn"
                class="submit_btn"
              >
                确定
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { editMicroWish, addMicroWish } from "@/api/microWish";
export default {
  props: {
    dialog: Boolean,
    rowData: Object,
    distributionArr: Array,
    isPoorHouseholdsArr: Array,
  },
  components: {},
  data() {
    return {
      submitBtnLoading: false,
    };
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    closeDialog() {
      this.$emit("closeDialog", false);
    },
    changeWishSituation(val) {
      if (val == "未完成") {
        this.rowData.completeDate = null;
      }
    },
    notEmptyJudge() {
      let row = this.rowData;
      let phoneReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      let isNumReg = /^[0-9]*$/;
      let cardIdReg =
        /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
      if (!row.applicant) {
        this.$message.info("请填写姓名!");
        return false;
      } else if (!row.cardId) {
        this.$message.info("请填写身份证号");
        return false;
      } else if (!cardIdReg.test(row.cardId)) {
        this.$message.info("身份证号码格式不正确!");
        return false;
      } else if (!row.phone) {
        this.$message.info("请填写手机号!");
        return false;
      } else if (row.phone.length != 11) {
        this.$message.info("请填写11位数的手机号!");
        return false;
      } else if (!phoneReg.test(row.phone)) {
        this.$message.info("手机号格式不正确");
        return false;
      } else if (!row.age) {
        this.$message.info("请填写年龄!");
        return false;
      } else if (!isNumReg.test(row.age)) {
        this.$message.info("年龄格式不正确!");
      } else if (!row.addr) {
        this.$message.info("请填写住址!");
        return false;
      } else if (!row.poorFlag) {
        this.$message.info("请选择是否贫困户");
        return false;
      } else if (!row.wishSituation) {
        this.$message.info("请选择发放情况!");
        return false;
      } else {
        return true;
      }
    },
    submitBtn() {
      if (this.submitBtnLoading == false) {
        let interfaceName = this.rowData.id ? editMicroWish : addMicroWish;
        let data = this.rowData.id ? [this.rowData] : this.rowData;
        data.branchId = this.$route.query.branchId;
        console.log(data);
        if (this.notEmptyJudge()) {
          this.submitBtnLoading = true;
          interfaceName(data)
            .then((res) => {
              console.log(res);
              this.submitBtnLoading = false;
              if (res.code == 200) {
                this.$message.success(
                  this.rowData.id ? "修改成功" : "添加成功"
                );
                this.closeDialog();
                this.$emit("getList");
              } else {
                this.$message.info(res.msg);
              }
            })
            .catch((err) => {
              this.submitBtnLoading = false;
            });
        }
      }
    },
  },
};
</script>
<style lang='scss' scoped>
.dialog_cover_cloth {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 1;
  top: 0;
  .dialog_box {
    width: 100vw;
    height: 100vw;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    margin-top: 15vh;
    .box_content {
      background: transparent;
      width: 48vw;
      position: relative;
      .bg_img {
        width: 48vw;
        img {
          width: 100%;
        }
      }
      .content_box {
        position: absolute;
        top: 0;
        color: #fff;
        // padding-top: 0.5vw;
        width: 48vw;
        line-height: 2vw;
        .box_header {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          position: relative;
          .title {
            color: #ffd901;
            padding-top: 0.5vw;
            font-weight: bold;
            font-size: 0.9vw;
          }
          .close_btn {
            position: absolute;
            top: -1vw;
            right: -0.3vw;
            cursor: pointer;
            opacity: 0;
            padding: 0.5vw;
          }
        }
        .box_conter {
          display: flex;
          justify-content: space-around;
          margin-top: 2vw;
          font-size: 0.8vw;
          flex-wrap: wrap;
          .form_row {
            display: flex;
            align-items: center;
            margin-bottom: 1vw;
            height: 3vw;
            .label_span {
              width: 5vw;
            }
            .value_span {
              width: 14.5vw;
            }
          }
          .conter_bottom {
            flex: 1;
            padding: 0 2vw;
            .value_span {
              flex: 1;
            }
          }
        }
        .box_footer {
          display: flex;
          align-items: center;
          justify-content: center;
          .btn_box {
            display: flex;
            div {
              border: 0.1vw solid #5384e5;
              margin-right: 1vw;
              padding: 0.1vw 4vw;
              cursor: pointer;
              font-size: 0.8vw;
            }
          }
        }
      }
    }
  }
}
</style>