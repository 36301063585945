<template>
  <div class="page_body">
    <div class="page_box">
      <div class="header">
        <img class="header-logo" src="../../assets/image/wjlogo.png" />
        旺家·东泰共建数字社区
      </div>
      <!-- 返回 刷新 -->
      <rightNav></rightNav>
      <div class="page-title">{{ branchName }}</div>
      <!-- 时间 -->
      <div class="time-box">
        <Date></Date>
      </div>
      <div class="page_content">
        <div class="query_form">
          <el-form :inline="true" :model="formInline" class="demo-form-inline">
            <el-form-item label="姓名">
              <el-input v-model="formInline.applicant"></el-input>
            </el-form-item>
            <el-form-item label="是否贫困户">
              <!-- placeholder="请选择类别" -->
              <el-select v-model="formInline.poorFlag" placeholder="">
                <el-option
                  v-for="(item, index) in isPoorHouseholdsArr"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="发放情况">
              <el-select v-model="formInline.wishSituation" placeholder="">
                <el-option
                  v-for="(item, index) in distributionArr"
                  :key="index"
                  :label="item.distribution"
                  :value="item.distribution"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <span class="query_btn" @click="onSubmit">搜 索</span>
              <span class="reset_btn" @click="onReset">重 置</span>
            </el-form-item>
          </el-form>
          <div class="add_btn_box">
            <span @click="addBtn" class="add_btn">新增</span>
            <span @click="importBtn" class="import_btn">导入</span>
          </div>
        </div>
        <tableList
          :loading="loading"
          :total="total"
          :formInline="formInline"
          :tableData="tableData"
          @changePage="changePage"
          @editDialog="editDialog"
          @onDelete="onDelete"
        ></tableList>
      </div>
    </div>
    <editDialog
      :distributionArr="distributionArr"
      :isPoorHouseholdsArr="isPoorHouseholdsArr"
      :rowData="rowData"
      :dialog="dialog"
      @closeDialog="closeDialog"
      @getList="getList"
    ></editDialog>
    <dialogAlert
      :dialogVisible="dialogVisible"
      :dialogData="dialogData"
      @closeDialog="delCloseDialog"
      @determine="determine"
      :dialogTitle="dialogTitle"
    >
      <span slot="txt">{{ delDataTxt }}</span>
    </dialogAlert>
    <importDialog
      @importClose="importClose"
      :importDialog="importDialog"
      @importSuccess="importSuccess"
    ></importDialog>
  </div>
</template>
<script>
import Date from "@/views/commonComponents/date.vue";
import rightNav from "@/views/commonComponents/rightNav.vue";
import tableList from "./components/tableList.vue";
import editDialog from "./components/editDialog.vue";
import dialogAlert from "@/views/homeImg/components/dialogAlert.vue";
import importDialog from "./components/importDialog.vue";
import {
  getPartyBranchList,
  getMicroWishList,
  delMicroWish,
} from "@/api/microWish";
export default {
  components: {
    Date,
    rightNav,
    tableList,
    editDialog,
    dialogAlert,
    importDialog,
  },
  data() {
    return {
      branchName: "",
      formInline: {
        pageNum: 1,
        pageSize: 10,
        branchId: "",
      },
      total: 20,
      distributionArr: [
        {
          distribution: "已发放",
        },
        {
          distribution: "未发放",
        },
      ],
      isPoorHouseholdsArr: [
        {
          value: "1",
          label: "是",
        },
        {
          value: "0",
          label: "否",
        },
      ],
      options: [],
      tableData: [
        {
          id: "0",
          applicant: "zhangsan",
          idNum: "440785199711203117",
          age: "25",
          phone: "13242854945",
          address: "广州市黄浦区神州路",
          isPoorHouseholds: "1",
          distribution: "1",
        },
      ],
      submitBtnLoading: false,
      loading: false,
      dialog: false,
      rowData: {},
      dialogVisible: false,
      delDataTxt: "",
      dialogData: {},
      importDialog: false,
      dialogTitle: "系统提示",
    };
  },
  watch: {},
  async created() {
    this.branchName = this.$route.query.branchName;
    await this.getPartyBranchList();
    this.formInline.branchId = this.$route.query.branchId * 1;
    await this.getList();
  },
  mounted() {},
  methods: {
    importSuccess() {
      this.importDialog = false;
      this.getList();
    },
    addBtn() {
      this.dialog = true;
    },
    importClose(val) {
      this.importDialog = val;
    },
    importBtn() {
      this.importDialog = true;
    },
    onDelete(row) {
      console.log(row);
      this.dialogData = row;
      this.delDataTxt = `是否确认删除姓名为${row.applicant}的数据项吗?`;
      this.dialogVisible = true;
    },
    delCloseDialog() {
      this.dialogVisible = false;
    },
    determine() {
      delMicroWish(this.dialogData.id)
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("删除成功");
            this.dialogVisible = false;
            this.getList();
          }
        })
        .catch((err) => {
          this.$message.info(res.msg);
        });
    },
    closeDialog(value) {
      this.rowData = {};
      this.dialog = value;
    },
    editDialog(val) {
      this.dialog = true;
      this.rowData = JSON.parse(JSON.stringify(val));
    },
    changePage(val) {
      this.formInline.pageNum = val;
      this.getList();
    },
    async getList() {
      this.loading = true;
      let { rows, total } = await getMicroWishList(this.formInline);
      // rows.map((item) => {
      //   item.encryptionPhone =
      //     item.phone.substr(0, 2) + "*******" + item.phone.substr(9);
      //   item.encryptionCardId =
      //     item.cardId.substr(0, 2) + "**************" + item.cardId.substr(16);
      // });
      console.log("获取微心愿列表", rows);
      this.loading = false;
      this.tableData = rows;
      this.total = total;
    },
    async getPartyBranchList() {
      let { rows } = await getPartyBranchList();
      console.log("获取党支部列表", rows);
      this.options = rows;
    },
    onSubmit() {
      this.formInline.pageNum = 1;
      this.getList();
    },
    onReset() {
      this.formInline = {
        pageNum: 1,
        pageSize: 10,
        branchId: this.$route.query.branchId,
      };
      this.getList();
    },
  },
};
</script>
<style lang='scss' scoped>
@import "./index.scss";
</style>
